module.exports = ['md5', 'dateUtils', 'commonUtils', 'addressUtils', 'uuid', 'apiQueries', 'apiService',
  (md5, dateUtils, commonUtils, addressUtils, uuid, apiQueries, apiService) => {
    'use strict';

    let that = {};

    //-- Color functions ---------------------------------------------------------------------

    that.generateColorFromString = (key) => '#' + md5.createHash(key).slice(0, 6).toUpperCase();

    that.lightenDarkenColor = (color, lum) => {

      // validate hex string
      let hex = String(color).replace(/[^0-9a-f]/gi, '');
      if (hex.length < 6) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
      }
      lum = lum || 0;

      // convert to decimal and change luminosity
      let rgb = "#", c, i;
      for (i = 0; i < 3; i++) {
        c = parseInt(hex.substr(i * 2, 2), 16);
        c = Math.round(Math.min(Math.max(0, c + c * lum), 200)).toString(16);
        rgb += ("00" + c).substr(c.length);
      }

      return rgb;
    };

    //----------------------------------------------------------------------------------------

    //-- String functions ---------------------------------------------------------------------

    that.textPartialMatch = (text1, text2) => text1.trim().toLowerCase().includes(text2.trim().toLowerCase());

    that.capitalize = (text) => text ? text.charAt(0).toUpperCase() + text.substr(1).toLowerCase() : '';

    //----------------------------------------------------------------------------------------

    //-- Date functions ----------------------------------------------------------------------

    that.dateToIso = date => dateUtils.toString(date);

    that.isoToDate = dateString => dateUtils.parse(dateString);

    that.abolished = (resource, noFinishEarlierThan) => {
      if (!noFinishEarlierThan) {
        return false;
      }

      return dateUtils.isBefore(resource.endDate, noFinishEarlierThan);
    };

    that.isActiveInPeriod = (resource, period) => {
      return dateUtils.isCovering(period, location);
    };

    that.isAValidPeriod = (startDate, endDate) => !endDate || dateUtils.isBefore(startDate, endDate);

    // check if periodA in included in periodB
    that.isCovering = (periodA, periodB) => dateUtils.isCovering(periodA, periodB);

    that.isOverlapping = (periodA, periodB) => dateUtils.isOverlapping(periodA, periodB);

    // checks if two date periods overlap
    that.overlapingPeriods = (period1, period2) => {
      var endDate1 = period1.endDate || '9999-12-31',
        endDate2 = period2.endDate || '9999-12-31',
        minEndDate = that.minDate([that.dateToIso(endDate1), that.dateToIso(endDate2)]),
        maxStartDate = that.maxDate([that.dateToIso(period1.startDate), that.dateToIso(period2.startDate)]);

      var overlap = that.maxDate([0, that.isoToDate(minEndDate) - that.isoToDate(maxStartDate)]);

      return overlap > 0;
    };

    that.minDate = dates => _.min(dates);

    that.maxDate = dates => {
      if (dates.indexOf(undefined) > -1 || dates.indexOf(null) > -1) {
        return null;
      }

      return _.max(dates);
    };

    that.getFirstOfSeptemberOfCurrentYear = () =>
      dateUtils.toString(new Date(new Date().getFullYear(), 8, 1));

    //----------------------------------------------------------------------------------------

    //-- physical location functions ---------------------------------------------------------

    that.physicalLocationToString = (address, prefix) => {
      if (_.isUndefined(address)) {
        return prefix;
      }

      let addressStr = (address.street ? address.street : '') +
        (address.houseNumber ? ' ' + address.houseNumber : '') +
        (address.mailboxNumber ? ' bus ' + address.mailboxNumber : '') +
        ', ' + address.zipCode + ' ';

      if (address.city === address.subCity) {
        addressStr += that.capitalize(address.city);
      } else {
        addressStr += address.city +
          (that.capitalize(address.subCity) ? ' (' + that.capitalize(address.subCity) + ')' : '');
      }

      return (prefix || '') + ' ' + addressStr;
    };

    that.similarAddress = (pl1, pl2) => {
      return addressUtils.isSameStreet(pl1, pl2) &&
        addressUtils.isSameHouseNumberAndMailbox(pl1, pl2);
    };

    that.capitalizeFirstLetter = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1);
    };

    that.quoteText = (string) => {
      return '\'' + string + '\'';
    };

    that.adjustAddress = async (address) => {
      if (address.subCity) {
        address.subCity = address.subCity.replace(/\s*\([^()]*\)$/g, '');
      }

      if (!address.subCityHref) {
        await addressUtils.addSubCityHref(address, apiService.getApi(), dateUtils);
      }
      if (!address.streethref) {
        await addressUtils.addStreetHref(address, apiService.getApi(), dateUtils, true);
      }
    };

    that.findExistingPhysicalLocation = async (address) => {
      const params = address.cityHref ? { 'address.cityHref': address.cityHref, type: 'DOMAIN' } : { 'address.city': address.city, type: 'DOMAIN' };
      let physicalLocations = await apiQueries.getPhysicalLocations(params);

      const matches = physicalLocations.filter((pl) =>
        addressUtils.isSameStreet(pl.address, address)
        && addressUtils.isSameHouseNumberAndMailbox(pl.address, address));

      return matches.length > 0 ? matches[0] : null;
    };

    that.createPhysicalLocation = async (address) => {
      let physicallocationkey = uuid.v4();

      const physicalLocation = {
        $$meta: { href: '/sam/physicallocations/' + physicallocationkey },
        key: physicallocationkey,
        type: 'DOMAIN',
        status: 'IN_USE',
        address: address
      };

      await apiQueries.putPhysicalLocation(physicalLocation);
      return physicalLocation;
    };

    //-----------------------------------------------------------------------------------------

    //--modals --------------------------------------------------------------------------------

    that.notify = (scope, type, icon, message) => {
      scope.$applyAsync(() => {
        scope.notificationOptions.enabled = true;
        scope.notificationOptions.type = type;
        scope.notificationOptions.icon = icon;
        scope.notificationOptions.message = message;
      });
    };

    //-----------------------------------------------------------------------------------------

    //-- cluster operations -------------------------------------------------------------------

    that.getActiveParent = (schoolOrCluster, when = dateUtils.getNow()) => {

      if (schoolOrCluster.$$parents && schoolOrCluster.$$parents.length > 0) {
        const activeParentships = dateUtils.getActiveResources(schoolOrCluster.$$parents, when);
        return activeParentships && activeParentships.length > 0 ? activeParentships[0] : null;
      }
      return null;
    };

    that.isClusterUpdateNeeded = (sourceCluster, targetCluster) => {
      return sourceCluster && targetCluster && sourceCluster/*.to.$$expanded*/.key !== targetCluster.key;
    };

    that.isClusterUpdateValid = (sourceCluster, targetCluster, when) => {
      if (that.isClusterUpdateNeeded(sourceCluster, targetCluster)) {
        return dateUtils.getActiveResources(sourceCluster/*.to.$$expanded*/.$$children, when).length > 2;
      }

      return true;
    };

    that.getClusterMovementsRequiered = (schoolsOrClusters, when) => {
      return schoolsOrClusters
        .map(schoolEntity => that.getActiveParent(schoolEntity, when))
        .filter(sourceCluster => sourceCluster && sourceCluster.startDate === when);
    };

    that.getConflictingClusters = (clusterMovements, when, targetCluster) => {

      /*return clusterMovements.filter(sourceCluster =>
        !that.isClusterUpdateValid(sourceCluster.to.$$expanded, targetCluster, when));*/

      const clusterChildrenMap = {};

      clusterMovements.forEach(clusterMovement => {
        const sourceClusterKey = clusterMovement.to.href.split('/').pop();

        if (!clusterChildrenMap[sourceClusterKey]) {
          clusterChildrenMap[sourceClusterKey] = clusterMovement.to.$$expanded;
        }

        clusterChildrenMap[sourceClusterKey].$$children =
          clusterChildrenMap[sourceClusterKey].$$children.filter(child => child.key !== clusterMovement.key);
      });

      return Object.values(clusterChildrenMap).filter(sourceCluster =>
        !that.isClusterUpdateValid(clusterChildrenMap[sourceCluster.key], targetCluster, when));
    };

    that.moveToCluster = (batch, schoolOrCluster, targetCluster, when = dateUtils.getNow()) => {

      const activeParent = that.getActiveParent(schoolOrCluster, when);

      if (activeParent && activeParent.startDate !== when &&
        that.isClusterUpdateNeeded(activeParent, targetCluster)) {
        let oldRelationEndDate = dateUtils.getPreviousDay(when);
        batch.put('/sam/organisationalunits/relations/' + activeParent.key, {
          key: activeParent.key,
          from: {href: activeParent.from.href},
          to: {href: activeParent.to.href},
          type: 'IS_PART_OF',
          startDate: activeParent.startDate,
          endDate: dateUtils.isBefore(activeParent.endDate, oldRelationEndDate) ?
            activeParent.endDate : oldRelationEndDate
        });
      }

      // link to target
      if (targetCluster) {
        const newRelationKey = uuid.v4();
        batch.put('/sam/organisationalunits/relations/' + newRelationKey, {
          key: newRelationKey,
          from: {href: '/sam/organisationalunits/' + schoolOrCluster.key},
          to: {href: '/sam/organisationalunits/' + targetCluster.key},
          type: 'IS_PART_OF',
          startDate: when
        });
      }
    };

    that.isValidCluster = (childRelations) => {

      for (let childRelation of childRelations) {
        const childRelationsInSamePeriod = childRelations.filter(cr =>
          cr.key !== childRelation.key &&
          cr.startDate === childRelation.startDate &&
          cr.endDate === childRelation.endDate || !cr.endDate);

        if (childRelationsInSamePeriod.length < 2) {
          return false;
        }
      }
      return true;
    };

    that.canBeClusteredWith = (ou1, ou2) => {

      if (ou1.$$activeParent && ou2.$$activeParent &&
        ou1.$$activeParent.to.href === ou2.$$activeParent.to.href) {
        return true;
      } else if (ou1.$$activeParent && ou1.$$activeParent.to.href === ou2.$$meta.permalink ||
        ou2.$$activeParent && ou2.$$activeParent.to.href === ou1.$$meta.permalink ||
        ou2.$$activeParent && ou2.$$activeParent.from.href === ou1.$$meta.permalink ||
        ou1.$$activeParent && ou1.$$activeParent.from.href === ou2.$$meta.permalink) {
        return true;
      } else if (!ou1.$$activeParent && !ou2.$$activeParent) {
        return true;
      }
      return false;

    };

    //----------------------------------------------------------------------------------------

    //-- Common utils ------------------------------------------------------------------------

    that.strip$$Properties = (object) => commonUtils.strip$$Properties(object);
    that.strip$$PropertiesFromBatch = (batch) => commonUtils.strip$$PropertiesFromBatch(batch);

    //----------------------------------------------------------------------------------------

    //-- Parsing preloaded maps ---------------------------------------------------------------

    that.getLocation = (locationMap, ou, pl, period) => {
      return _.find(locationMap, location =>
        location.organisationalUnit.href === ou.$$meta.permalink &&
        location.physicalLocation.href === pl.$$meta.permalink &&
        dateUtils.isCovering(location, period)
      );
    };

    //----------------------------------------------------------------------------------------

    return that;
  }];
